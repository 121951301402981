body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
p,
blockquote,
hr,
figure,
th,
td {
  margin: 0;
  padding: 0;
}

h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1,
h2 {
  font-weight: 700;
}

small {
  font-weight: 400;
  font-size: 12px;
}

html {
  -webkit-font-smoothing: antialiased;
  font-size: 100%;
  font-family: "Titillium Web", sans-serif;
  letter-spacing: 0.4px;
}

strong,
b {
  font-weight: 700;
}

p {
  color: #6C6C6C;
  font-size: 18px;
  line-height: 1.6;
}

blockquote {
  font-size: 14px;
  font-style: italic;
  padding-left: 40px;
  border-left: 1px solid rgba(18, 18, 18, 0.2);
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: currentColor;
}

a:hover {
  text-decoration: underline;
}

a,
button {
  transition: all 300ms ease-in-out;
  font: inherit;
}

a:hover,
button:hover {
  cursor: pointer;
}

input,
textarea,
button {
  outline: none;
  border: 0;
  background: transparent;
}

body {
  color: #444444;
  background-color: #ffffff;
}

.clear {
  width: 100%;
  clear: both;
  display: block;
  height: 0px;
}

img {
  max-width: 100%;
  height: auto;
  word-break: break-all;
}

input,
textarea,
select {
  color: inherit;
  font-family: inherit;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: currentColor;
  opacity: 0.85;
}

table {
  width: 100%;
}

::selection {
  background: #ccc;
  color: #fff;
}

.icon {
  position: relative;
}
.icon svg {
  display: block;
}

svg {
  fill: currentColor;
}

.post {
  position: relative;
  min-height: 400px;
}
.post-content {
  font-size: 1.3rem;
  line-height: 2;
  font-weight: 300;
  max-width: 700px;
  margin: 0 auto;
}
.post-content > * {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.summernote p,
.summernote span,
.summernote h2,
.summernote h3,
.summernote h4,
.summernote h5,
.summernote h6,
.summernote strong,
.summernote b,
.summernote ul,
.summernote li,
.summernote a {
  font-family: "Heebo", sans-serif !important;
  line-height: 2;
  color: #444444;
  letter-spacing: 0px;
}
.summernote.summernote-white p,
.summernote.summernote-white span,
.summernote.summernote-white h2,
.summernote.summernote-white h3,
.summernote.summernote-white h4,
.summernote.summernote-white h5,
.summernote.summernote-white h6,
.summernote.summernote-white strong,
.summernote.summernote-white b,
.summernote.summernote-white ul,
.summernote.summernote-white li,
.summernote.summernote-white a {
  color: #ffffff;
}
.summernote p,
.summernote span,
.summernote strong,
.summernote b,
.summernote ul,
.summernote li,
.summernote a {
  font-size: 18px;
}
.summernote a {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #F6842B !important;
  text-decoration: none !important;
  border-bottom: none !important;
}
.summernote a:hover {
  text-decoration: underline !important;
}
.summernote h2 {
  font-size: 32px !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  line-height: 1 !important;
}
.summernote h3,
.summernote h4,
.summernote h5,
.summernote h6 {
  font-size: 24px !important;
  font-weight: 700 !important;
  letter-spacing: 0px !important;
  line-height: 1 !important;
  color: #F6842B;
}
.summernote ul {
  padding-left: 40px !important;
  list-style: disc !important;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.hidden {
  display: none !important;
}

.ngxImageZoomContainer {
  width: inherit !important;
  height: inherit !important;
  position: relative;
  overflow: hidden;
}
.ngxImageZoomContainer:hover {
  cursor: zoom-in;
}
.ngxImageZoomContainer img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  object-position: top center;
  background-color: #ffffff;
}

@media screen and (min-width: 800px) {
  .hidden-desktop {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  body {
    padding: 60px 0 0;
  }
  .summernote iframe {
    min-height: 240px;
  }
  .summernote .note-float-left,
.summernote .note-float-right {
    margin-right: 0px;
    margin-left: 0px;
    width: 100% !important;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .summernote img {
    object-fit: cover;
  }
  .hidden-mobile {
    display: none !important;
  }
  .post-content {
    font-size: 1.15rem;
  }
  .post-content > * {
    width: 100%;
    max-width: 100%;
  }
}
.container {
  width: 1600px;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 0 5%;
}
.container.container-small {
  width: 1280px;
}

.header-menu-search input {
  color: #fff;
}
.header-menu-search input::placeholder {
  color: #fff;
  opacity: 0.8;
}

.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.home-box {
  flex: 0 1 32.33%;
  width: 32.33%;
  min-height: 120px;
  border-bottom: 4px solid #F6842B;
}
.home-box span {
  color: #F6842B;
  font-size: 24px;
}
.home-box:nth-child(3n-1) {
  margin-left: 1.5%;
  margin-right: 1.5%;
}
.home-title {
  font-size: 32px;
  margin-bottom: 60px;
}

.header-user .mat-button-wrapper {
  display: inline-flex !important;
  align-items: center;
}

main.logged {
  padding-top: 56px;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rate-limit-reached {
  color: #F6842B;
  max-width: 360px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
}

.user {
  display: inline-flex;
  align-items: center;
  flex-flow: row;
}
.user-avatar {
  min-height: 36px;
  min-width: 36px;
  position: relative;
}
.user-avatar img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.user-name {
  display: inline-block;
  margin-left: 16px;
}

.no-padding.mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}
.no-padding.mat-form-field .mat-form-field-underline {
  bottom: 0 !important;
}

.page {
  margin-top: 60px;
  padding-bottom: 100px;
}
.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}
.page-header__buttons {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}
.page-header__buttons button,
.page-header__buttons a {
  margin-left: 24px;
}
.page-header__buttons .mat-button-toggle-button {
  margin-left: 0px;
}
.page-header__buttons .mat-form-field {
  margin-left: 24px;
}
.page-header__buttons .mat-form-field.no-margin .mat-form-field-underline {
  bottom: 0;
}
.page-header__buttons .mat-form-field.no-margin .mat-form-field-wrapper {
  padding-bottom: 0;
}
.page-title {
  font-size: 32px;
  font-weight: 700;
}
.page-wrapper {
  position: relative;
}
.page-boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  padding: 24px 16px;
}
.page-boxes-buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  flex: 0 0 100%;
}
.page-boxes-buttons h2 {
  font-size: 18px;
}
.page-boxes-buttons button {
  margin-left: 16px;
}
.page-box {
  flex: 0 1 100%;
  width: 100%;
  margin-bottom: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-bottom: 2px solid #F6842B;
}
.page-box.half {
  flex: 0 1 49%;
  width: 49%;
}
.page-box.third {
  flex: 0 1 32.33%;
  width: 32.33%;
}
.page-box-header {
  padding: 16px 24px;
  background: #f7f7f7;
}
.page-box-header h3 {
  font-size: 18px;
  color: #242533;
}
.page-box-item__label {
  color: #6C6C6C;
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.page-box-item__value {
  font-size: 16px;
}
.page-box-content {
  padding: 16px 24px;
}
.page-box-content ul li + li {
  margin-top: 10px;
}
.page-content {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
  padding: 24px 40px;
}
.page-table {
  position: relative;
  /* Column Widths */
}
.page-table__search {
  font-size: 14px;
  width: 100%;
}
.page-table-container {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  position: relative;
}
.page-table .mat-column-number,
.page-table .mat-column-state {
  max-width: 64px;
}
.page-table .mat-column-created {
  max-width: 124px;
}
.page-table td:last-child,
.page-table th:last-child {
  text-align: right;
}
.page-table td.mat-cell:not(:first-child):not(:last-child),
.page-table th.mat-footer-cell:not(:first-child):not(:last-child),
.page-table th.mat-header-cell:not(:first-child):not(:last-child) {
  padding: 0 0px 0 8px;
}
.page-table td.mat-cell {
  max-width: 300px;
}
.page-table-image {
  height: 75px;
  width: 75px;
  object-fit: cover;
  display: block;
}
.page-tab-group {
  flex: 0 0 100%;
  width: 100%;
}
.page-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
  padding: 23px 40px;
  border-bottom: 4px solid #F6842B;
  position: relative;
}
.page-form__title {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 23px;
  color: #F6842B;
  flex: 0 1 100%;
  width: 100%;
}
.page-form hr {
  flex: 0 1 100%;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin: 30px 0;
}
.page-form-loading {
  position: absolute !important;
  top: 0;
  left: 0;
  z-index: 100;
}
.page-form-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
}
.page-form-control {
  flex: 0 1 100%;
  width: 100%;
  margin-bottom: 1.25em;
}
.page-form-control.half {
  flex: 0 1 49%;
  width: 49%;
}
.page-form-control.third {
  flex: 0 1 32.33%;
  width: 32.33%;
}
.page-form-control.two-third {
  flex: 0 1 65.66%;
  width: 65.66%;
}
.page-form-control.quad {
  flex: 0 1 24%;
  width: 24%;
}
.page-form-control > label {
  margin-bottom: 10px;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  font-size: 13px;
}
.page-form-control.has-icon {
  position: relative;
  padding-left: 50px;
}
.page-form-control__icon {
  width: 50px;
  height: 50px;
  position: absolute;
  left: -50px;
  top: 50%;
  transform: translateY(-50%);
}
.page-form-control__icon svg {
  width: 22px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-form .page-radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.page-form .page-radio-group-label {
  font-size: 14px;
  color: #F6842B;
}
.page-form .page-radio-button {
  margin: 5px;
}
.page-form-image {
  position: relative;
}
.page-form-image img {
  width: 240px;
  display: block;
  margin-bottom: 10px;
}
.page-form-image__button {
  position: absolute;
  left: 16px;
  top: 16px;
  background: #eb2336;
  height: 32px;
  width: 32px;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  color: #fff;
}
.page-filters {
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 16px;
}

.list {
  width: 100%;
  flex: 0 0 100%;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}
.list-header {
  font-size: 16px;
  font-weight: 700;
  color: #6C6C6C;
  margin-bottom: 16px;
}
.list-footer {
  margin-top: 16px;
  text-align: right;
}
.list-item {
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
}
.list-item-wrapper {
  padding: 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.list-item:last-child {
  border: none;
}
.list-item-placeholder {
  background: #f7f7f7;
  border: dotted 2px #cccccc;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.list-item__buttons {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.list-item__buttons button[cdkDragHandle] {
  cursor: move;
}
.list-item__color {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 50%;
  display: block;
  margin-right: 16px;
}
.list-item__name {
  flex-shrink: 1;
  width: 100%;
}
.list-item__form {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.list-item__form mat-form-field {
  margin-right: 16px;
}
.list.cdk-drop-list-dragging .list-item:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.list + .list {
  margin-top: 60px;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.chips {
  margin: 16px 0;
}
.chips__title {
  font-size: 16px;
  margin-bottom: 16px;
  font-weight: 400;
}
.chips-list .mat-chip-list-wrapper {
  margin: 0px;
}
.chips-list .chips-item {
  cursor: pointer;
}

.mat-step .mat-step-header.disabled {
  pointer-events: none !important;
}

.float-title {
  position: fixed;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
  bottom: 2.5%;
  right: 2.5%;
  background: #F6842B;
  color: #ffffff;
  border-radius: 6px;
  padding: 16px;
  font-size: 18px;
  z-index: 98;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 0 5%;
  }
  .container.container-small {
    width: 100%;
    padding: 0 5%;
  }
}