// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$telmaster-primary: mat.define-palette(
    (
        50: #fef0e6,
        100: #fcdabf,
        200: #fbc295,
        300: #f9a96b,
        400: #f7964b,
        500: #f6842b,
        600: #f57c26,
        700: #f37120,
        800: #f2671a,
        900: #ef5410,
        A100: #ffffff,
        A200: #ffefe9,
        A400: #ffc9b6,
        A700: #ffb79c,
        contrast: (
            50: #000000,
            100: #000000,
            200: #000000,
            300: #000000,
            400: #000000,
            500: #000000,
            600: #000000,
            700: #000000,
            800: #000000,
            900: #ffffff,
            A100: #000000,
            A200: #000000,
            A400: #000000,
            A700: #000000,
        ),
    )
);
$telmaster-accent: mat.define-palette(
    (
        50: #e5e5e7,
        100: #bdbec2,
        200: #929299,
        300: #666670,
        400: #454652,
        500: #242533,
        600: #20212e,
        700: #1b1b27,
        800: #161620,
        900: #0d0d14,
        A100: #5959ff,
        A200: #2626ff,
        A400: #0000f2,
        A700: #0000d9,
        contrast: (
            50: #000000,
            100: #000000,
            200: #000000,
            300: #ffffff,
            400: #ffffff,
            500: #ffffff,
            600: #ffffff,
            700: #ffffff,
            800: #ffffff,
            900: #ffffff,
            A100: #ffffff,
            A200: #ffffff,
            A400: #ffffff,
            A700: #ffffff,
        ),
    )
);

// The warn palette is optional (defaults to red).
$telmaster-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$telmaster-theme: mat.define-light-theme($telmaster-primary, $telmaster-accent, $telmaster-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($telmaster-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: "Heebo", sans-serif;
}
